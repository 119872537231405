"use strict";
/// <reference types="waypoints"/>
Object.defineProperty(exports, "__esModule", { value: true });
require("core-js/stable/dom-collections/for-each");
require("classlist-polyfill");
require("element-closest-polyfill");
require("waypoints/lib/noframework.waypoints.js");
require("bootstrap/js/dist/collapse");
var ease_component_1 = require("ease-component");
var button_1 = require("./button");
var form_1 = require("./modals/form");
var image_1 = require("./modals/image");
var media_1 = require("./modals/media");
var scroll_to_1 = require("./scroll-to");
var swiper_slider_1 = require("./swiper-slider");
var slider_range_1 = require("./slider-range");
var smart_filter_1 = require("./smart-filter");
var tabs_1 = require("./tabs");
var toggler_1 = require("./toggler");
var tooltip_1 = require("./tooltip");
var deferred_load_1 = require("./deferred-load");
require("./../scss/_variables");
require("bootstrap/scss/bootstrap");
require("./../scss/main");
var Application = /** @class */ (function () {
    function Application() {
    }
    Application.prototype.run = function () {
        this.initButtons();
        this.initModalForm();
        this.initModalImage();
        this.initModalMedia();
        this.initScrollTo();
        this.initSlider();
        this.initSliderRange();
        this.initSmartFilter();
        this.initTabs();
        this.initToggler();
        this.initWaypoints();
        this.initTooltip();
        this.initDeferredLoad();
        $('.btn-scroll-top').on('click', function (event) {
            event.preventDefault();
            $("html, body").animate({ scrollTop: 0 }, 2000, 'linear');
        });
    };
    Application.prototype.initButtons = function () {
        var items = document.querySelectorAll('.button-component');
        items.forEach(function (item) {
            new button_1.Button({
                element: item,
                onClick: function (event) {
                    console.log(event);
                }
            }).run();
        });
    };
    Application.prototype.initSlider = function () {
        var sliders = document.querySelectorAll('.j-slider-component');
        sliders.forEach(function (slider) {
            new swiper_slider_1.SwiperSlider({
                element: slider,
                options: {}
            }).run();
        });
        var productCardThumbSlider = document.querySelector('.j-product-card-thumb-slider');
        var productCardThumbSliderClass = null;
        if (productCardThumbSlider !== null) {
            productCardThumbSliderClass = new swiper_slider_1.SwiperSlider({
                element: productCardThumbSlider,
                options: {}
            }).run();
        }
        var productCardSlider = document.querySelector('.j-product-card-slider');
        if (productCardSlider !== null) {
            var options = {};
            if (productCardThumbSliderClass !== null) {
                Object.assign(options, {
                    thumbs: {
                        swiper: productCardThumbSliderClass.getInstance()
                    }
                });
            }
            new swiper_slider_1.SwiperSlider({
                element: productCardSlider,
                options: options
            }).run();
        }
        var compareSlider = document.querySelector('.compare__slider');
        if (compareSlider !== null) {
            new swiper_slider_1.SwiperSlider({
                element: compareSlider,
                options: {
                    observer: true,
                    observeParents: true,
                    watchOverflow: false,
                    autoplay: false,
                    slidesPerView: "auto",
                    spaceBetween: 0,
                    navigation: {
                        nextEl: '.compare-slider-next',
                        prevEl: '.compare-slider-prev',
                    },
                }
            }).run();
        }
    };
    Application.prototype.initModalForm = function () {
        new form_1.ModalForm({
            elementsSelector: '.modal-form__opener'
        }).run();
    };
    Application.prototype.initModalImage = function () {
        new image_1.ModalImage({
            elementsSelector: '.fancybox__image',
            options: {
                smallBtn: true
            }
        }).run();
    };
    Application.prototype.initModalMedia = function () {
        new media_1.ModalMedia({
            elementsSelector: '.fancybox__media'
        }).run();
    };
    Application.prototype.initTabs = function () {
        new tabs_1.Tabs({
            selector: '.tabs__list a'
        }).run();
    };
    Application.prototype.initToggler = function () {
        var _this = this;
        var items = document.querySelectorAll('.toggler__btn');
        items.forEach(function (item) {
            var element = item;
            if (!element.dataset.target) {
                return;
            }
            var target = document.querySelector(element.dataset.target);
            if (target === null) {
                return;
            }
            if (element.dataset.locked) {
                _this.initTogglerWithLockedBody(element, target);
            }
            else {
                _this.initTogglerDefault(element, target);
            }
        });
    };
    Application.prototype.initTogglerWithLockedBody = function (trigger, target) {
        var body = document.querySelector('body');
        new toggler_1.Toggler({
            element: trigger,
            target: target,
            removeElement: (trigger.dataset.removeTrigger !== undefined),
            elementSwitchedText: trigger.dataset.switchedText,
            elementSwitchedClassname: trigger.dataset.switchedClassName,
            switchOn: function () {
                if (body !== null) {
                    var overlay = document.createElement('div');
                    overlay.className = 'overlay';
                    body.appendChild(overlay);
                    body.classList.add('body-locked');
                }
            },
            switchOff: function () {
                if (body !== null) {
                    var overlay = document.querySelector('.overlay');
                    if (overlay !== null) {
                        body.removeChild(overlay);
                    }
                    body.classList.remove('body-locked');
                }
            }
        }).run();
    };
    Application.prototype.initTogglerDefault = function (trigger, target) {
        new toggler_1.Toggler({
            element: trigger,
            target: target,
            removeElement: (trigger.dataset.removeTrigger !== undefined),
            elementSwitchedText: trigger.dataset.switchedText,
            elementSwitchedClassname: trigger.dataset.switchedClassName,
        }).run();
    };
    Application.prototype.initScrollTo = function () {
        var items = document.querySelectorAll('.scroll__btn');
        items.forEach(function (item) {
            var element = item;
            if (element.dataset.scrollHref) {
                var scrollTarget = document.querySelector(element.dataset.scrollHref);
                if (scrollTarget !== null) {
                    new scroll_to_1.ScrollTo({
                        triggerElement: item,
                        destination: scrollTarget,
                        ease: ease_component_1.inOutSine,
                        scrollDuration: element.dataset.scrollDuration ? parseInt(element.dataset.scrollDuration) : 1000
                    }).run();
                }
            }
        });
    };
    Application.prototype.initWaypoints = function () {
        var stickyNavigation = document.querySelector('.sticky-navigation');
        var offsetElement = document.querySelector('.sticky-navigation__offset-element');
        var scrollBtn = document.querySelector('.scroll__btn');
        new Waypoint({
            element: offsetElement,
            offset: function () {
                return -offsetElement.offsetHeight;
            },
            handler: function (direction) {
                if (direction == 'down') {
                    stickyNavigation.classList.add('d-block');
                    scrollBtn.classList.add('d-block');
                }
                else {
                    stickyNavigation.classList.remove('d-block');
                    scrollBtn.classList.remove('d-block');
                }
            },
        });
    };
    Application.prototype.initSliderRange = function () {
        var items = document.querySelectorAll('.j-slider-range');
        items.forEach(function (item) {
            new slider_range_1.SliderRange({
                element: item
            });
        });
    };
    Application.prototype.initSmartFilter = function () {
        var items = document.querySelectorAll('.j-filter__form');
        items.forEach(function (item) {
            new smart_filter_1.SmartFilter({
                element: item,
                requestUrl: item.dataset.requestUrl
            });
        });
    };
    Application.prototype.initTooltip = function () {
        var items = document.querySelectorAll('.tooltip__trigger');
        items.forEach(function (item) {
            new tooltip_1.Tooltip({
                element: item,
            }).run();
        });
    };
    Application.prototype.initDeferredLoad = function () {
        var items = document.querySelectorAll('.j-deferred-load__container');
        items.forEach(function (item) {
            new deferred_load_1.DeferredLoad({
                container: item,
                triggerSelector: '.j-deferred-load__trigger',
            }).run();
        });
    };
    return Application;
}());
document.addEventListener('DOMContentLoaded', function (event) {
    new Application().run();
});
