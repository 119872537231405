"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Button = void 0;
var Button = /** @class */ (function () {
    function Button(config) {
        this.config = config;
    }
    Button.prototype.run = function () {
        this.initEventListeners();
    };
    Button.prototype.initEventListeners = function () {
        var _this = this;
        if (this.config.onClick) {
            this.config.element.addEventListener('click', function (event) { return _this.config.onClick(event); });
        }
    };
    return Button;
}());
exports.Button = Button;
